<template>
	<div class="product-container">
		<div class="scroll-container" style="height: auto;">
			<div class="bg-wrapper">
				<div class="content-wrapper">
					<div class="item-content-right">
						<!-- 详情 -->
						<div class="item-content item-content-detail">
							<p class="backBtn" @click="backBtn"></p>
							<div class="content-box">
								<div class="item-header">
									<div class="item-left">
										<h4 class="item-title">{{ productDetail && productDetail.title }}</h4>
										<div class="item-operation">
											<span :class="['collection', { active: productDetail.followStatus == 0 }]" @click="changeFollow">
												{{ productDetail && productDetail.followStatusText }}
											</span>
											<span class="activity-time">活动时间：{{ productDetail && productDetail.activityTime }}</span>
											<p class="tag-list">
												<span>{{ productDetail && productDetail.tag }}</span>
												<span v-if="productDetail.realmName">{{ productDetail.realmName }}</span>
												<span v-if="productDetail.productName">{{ productDetail.productName }}</span>
												<!-- <span>{{ productDetail && productDetail.productName }}</span> -->
											</p>
										</div>
									</div>
									<p class="ne_bookMeeting" v-if="productDetail.appointmentStatus != 2">
										<el-button
											v-if="productDetail.appointmentStatus === 1 || productDetail.appointmentStatus === null"
											@click.stop="openCode(true, productDetail.id, productDetail.activityTime, productDetail.appointmentStatus)"
											class="ne_bookMeeting_btn proDetil"
											round
										>
											预约会议
										</el-button>
										<el-button v-if="productDetail.appointmentStatus === 0" class="ne_bookMeeting_btn proDetil activeds" round>已预约</el-button>
									</p>
								</div>
								<div class="item-content-bottom">
									<div class="item-img"><img :src="productDetail && productDetail.detail" alt="" /></div>
									<div class="item-activity-detail">
										<h5 class="item-title">会议说明</h5>
										<p class="item-content-d" v-html="productDetail && productDetail.content">
											<!-- {{ productDetail && productDetail.content || '临床上，2型糖尿病（T2DM）与慢性心衰是典型的“难兄难弟”。数据表明，10%~30%的T2DM患者合并心衰，而慢性心衰患者中T2DM的患病率也高达30%[1]。关于降糖药物对心衰的影响，近年来颇受关注。二甲双胍作为国内外指南一致推荐的T2DM一线首选用药，是否同样适用于心衰患者？让我们一起来看看最新的指南和证据。' }} -->
										</p>

										<h5 class="item-title">会议讲者</h5>
										<div class="item-content-d" v-if="productDetail && productDetail.lecturerList">
											<p class="teacher-detail" v-for="(item, index, key) in productDetail.lecturerList" :key="key">
												<!-- <img :src="item.avatar" alt=""> -->
												<span>
													<i class="item-name">{{ item }}</i>
													<!-- <i class="item-name">{{ item.name }}</i> -->
													<!-- <i class="item-name-title">{{ item.title }}</i> -->
												</span>
											</p>
											<!-- <p class="teacher-detail">
                        <img src="./images/imgHead.png" alt="">
                        <span>
                          <i class="item-name">李华</i>
                          <i class="item-name-title">外科-住院医师</i>
                        </span>
                      </p> -->
										</div>
										<p class="audit-no" v-if="productDetail.auditNo">{{ productDetail.auditNo }}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bullet" v-if="isShowBullet">
			<div class="bullet-pop" @click.stop="closeCode">
				<div class="bullet-close"><img src="./images/close.png" /></div>
				<div class="bullet-code">
					<img v-if="imgData && imgData.qr_url" :src="imgData.qr_url" alt="公众号二维码" />
				</div>
				<div class="bullet-notes">
					<p>您已成功预约该会议</p>
					<p>请您关注公众号接收会议提醒</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swiper from 'swiper';
import indexApi from '@/api/index';
export default {
	data() {
		return {
			yearSwiper: null,
			monthSwiper: null,
			contentSwiper: null,

			yearList: [
				// 2019, 2020, 2021, 2022
			],
			monthList: [
				// 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
			],

			curYear: '',
			curMonth: '',
			curYearMonth: '',

			activityList: [],
			defaultImg: './images/headerImg.png',

			productDetail: {},

			monthLists: [], // 月份列表

			timer: null,

			monthYear: '', // 接口参数
			page: 1,
			pageSize: 2,
			proportion: '',

			isLeft: false,
			isRight: true,
			isShowBullet: false,
			imgData: "", //公众号关注二维码
			IgnoreSecurity: "",
			isSubscribe: false, //用户是否关注公众号
		};
	},
	created() {
		let that = this;
		let queryId = that.$route.query.id;
		that.IgnoreSecurity = that.$route.query.IgnoreSecurity;
		if (queryId) {
			that.getActivityDetail(queryId);
			if (that.IgnoreSecurity === ""){
				//获取用户是否关注公众号
				indexApi.getIsSubscribe({}).then((res)=>{
					if (res.code === 1000 && res.success){
						that.isSubscribe = res.data.isSubscribe;
						console.log(that.isSubscribe);
					} else {
						console.log("用户是否关注接口调用失败",res.message);
					}

				});
			}
		} else {
			that.$router.replace({ name: 'login' });
		}
	},
	mounted() {},
	methods: {
		// 详情返回
		backBtn() {
			this.$router.go(-1);
		},
		// 点击收藏-取消
		changeFollow() {
			let params = {
				dataId: this.productDetail && this.productDetail.id, //数据ID
				type: 2 //类型 1产品 2活动 3资讯 4 临床视角
			};
			indexApi.followOrCanFollow(params).then(
				res => {
					// this.isClocActive = !this.isClocActive;
					// this.isClocActive ? this.clocText = '取消' : this.clocText = '收藏'
					this.getActivityDetail(this.productDetail && this.productDetail.id);
				},
				err => {
					this.$message.success((err && err.message) || '操作失败，请联系管理员！');
				}
			);
		},

		//打开预约会议
		openCode(flag, id, startTime,appointmentStatus, index) {
			let that = this;
			let params = {id};
			that.$point.getQrUrl({sourceId:id},(res)=>{
				that.imgData = res;
			})
			console.log(flag,id,startTime, index,"点击预约按钮")
			that.$tools.getCurrentTime(startTime,appointmentStatus, num => {
				if (num === 2) {
					that.$message.error('会议已开始，请立即参会！');
					that.getActivityDetail(id);
					return;
				}
				indexApi.signActivity(params).then(res => {
					if (res.code === 1000 && res.success){
						that.$message.success(res.data.msg);
						console.log(res,res.data.msg)
						if (!that.isSubscribe){
							setTimeout(function(){
								that.$message.error("请您关注公众号接收会议提醒");
							},100)
							that.isShowBullet = true;
						}
						switch (flag) {
							case true:
								that.getActivityDetail(id)
								break;
						}
					} else {
						console.log("预约接口请求失败:",res.message);
					}
				});
			});
		},
		//关闭预约会议
		closeCode() {
			let that = this;
			that.isShowBullet = false;
		},

		// 获取日历详情 getActivityList
		getActivityDetail(id) {
			let that = this;
			let params = {
				id: id
			};
			if (that.IgnoreSecurity) {
				params.IgnoreSecurity = that.IgnoreSecurity;
			}
			// let headers = {
			//   'Authorization': that.$stroage.getLocalItem("token"),
			//   'Content-Type': '	application/x-www-form-urlencoded'
			// }
			let url = `/activity/detail/${id}`;
			indexApi.getActivityDetail(params, url).then(
				res => {
					that.productDetail = {};
					if (res && res.code === 1000) {
						let data = res && res.data;
						that.productDetail = data;
						that.productDetail.followStatusText = that.productDetail.followStatus == 1 ? '收藏' : '取消';

						that.productDetail.lecturerList = that.productDetail.lecturer && that.productDetail.lecturer.split(',');

						that.$tools.getCurrentTime(that.productDetail['activityTime'],that.productDetail['appointmentStatus'], num => {
							console.log(num);
							that.productDetail['appointmentStatus'] = num;
						});
						if (this.IgnoreSecurity) return;
						this.$tools.readyLoadDetail(this);
					} else {
						console.log(res);
						let _message = res.message || '当前内容已过期，请您查看其它内容。';
						this.$message.error(_message);
					}
				},
				err => {
					that.$message.success((err && err.message) || '获取失败，请联系管理员！');
				}
			);
		}
	},

	destroyed() {
		clearTimeout(this.timer);
	}
};
</script>

<style scoped lang="scss">
.scroll-container {
	overflow: initial;
}
.product-container {
	background-image: url('~./images/bg.jpg');
	background-size: cover;
	line-height: 1;
}

.bg-wrapper {
	display: flex;
	align-items: flex-start;
	// background: url("~./images/content-bg.png") center center /100% 100%;
	background: rgba(255, 255, 255, 0.7);
	height: 7.3rem;
	margin-top: 0.6rem;
	position: relative;
	// padding-top: .4rem;
	padding-bottom: 0.4rem;
	border-radius: 0.2rem;
	// opacity: .9;
}

.content-wrapper {
	display: flex;
	align-items: flex-start;
	width: 100%;
	// width: 11.15rem;
	//   margin-right: .1rem;
	// overflow: hidden;
	.item-left {
		float: left;
	}
	.item-year {
		position: absolute !important;
		left: -0.15rem !important;
		top: -0.3rem !important;
		background: url('~./images/search-samll.png') center center / 100% 100%;
		height: 1.3rem;
		width: 2rem;
		z-index: 12;
		.item-ul-year {
			padding: 0;
			li {
				cursor: pointer;
				text-align: center;
				font-size: 0.3rem;
				font-family: Merck;
				font-weight: 400;
				color: #ffffff;
				line-height: 0.5rem;
				padding: 0;
				height: 0.5rem;
				&.swiper-slide-prev {
					font-size: 0.14rem;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #ffffff;
					//   line-height: .15rem;
					opacity: 0.4;
				}
				&.swiper-slide-next {
					font-size: 0.14rem;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #ffffff;
					//   line-height: .15rem;
					opacity: 0.4;
				}
			}
		}
	}

	.item-month-wrap {
		width: 1.8rem;
		text-align: center;
		margin-top: 0.8rem;
		overflow: hidden;
		height: 5.5rem;
		.item-ul-month {
			li {
				cursor: pointer;
				padding: 0 0;
				font-size: 0.18px;
				font-family: Noto Sans CJK SC;
				font-weight: 500;
				color: #333333;
				line-height: 0.8rem;
				opacity: 0.6;
				height: 0.8rem !important;
				&.swiper-slide-prev {
					font-size: 0.2rem;
					font-family: Noto Sans CJK SC;
					font-weight: 500;
					color: #333333;
					// line-height: .28rem;
					opacity: 0.8;
				}
				&.swiper-slide-next {
					font-size: 0.2rem;
					font-family: Noto Sans CJK SC;
					font-weight: 500;
					color: #333333;
					// line-height: .28rem;
					opacity: 0.8;
				}
				&.swiper-slide-active {
					// width: 1.8rem;
					height: 0.8rem !important;
					background: url('~./images/clik-bg.png') center center / 100% 100%;
					font-size: 0.24rem;
					font-family: Noto Sans CJK SC;
					font-weight: 500;
					color: #ffffff;
					line-height: 0.8rem;
					opacity: 1;
				}
			}
		}
	}

	.item-progress-bar {
		width: 9.6rem;
		height: 0.02rem;
		background: #ebebeb;
		margin-top: 0.6rem;
		overflow: hidden;
		.item-progress-active {
			width: 20%;
			height: 0.2rem;
			background: #eb3c96;
		}
	}

	.item-content-right {
		flex: 1;
		height: 7.3rem;
		position: relative;
		overflow: hidden;

		.item-title-date {
			margin-left: 0.3rem;
			// margin-top: -.15rem;
			margin-top: 0.29rem;
			font-size: 0.22rem;
			font-family: Noto Sans CJK SC;
			font-weight: 500;
			color: #333333;
			line-height: 0.28rem;
		}
	}

	.item-content {
		margin: 0.5rem auto 0;
		position: relative;
		overflow: hidden;
		padding-top: 0.5rem;
		&.item-content-detail {
			// background: maroon;
			background: url('~./images/bg-1.png') center center / 100% 100%;
			width: 13.6rem;
			height: 6.67rem;

			z-index: 10;

			.backBtn {
				width: 0.52rem;
				height: 0.52rem;
				background: url('~./images/return.png') center center / 100% 100%;
				position: absolute;
				top: 0.4rem;
				left: 0.14rem;
				cursor: pointer;
			}
			.content-box {
				.item-header {
					overflow: hidden;

					.item-left {
						float: left;
						margin-left: 0.9rem;
						.item-title {
							width: 10rem;
							margin: 0.5rem 0 0.23rem;
							font-size: 0.3rem;
							font-family: Noto Sans CJK SC;
							font-weight: 500;
							color: #333333;
							line-height: 0.36rem;
						}
					}
					.item-operation {
						overflow: hidden;
						.collection {
							font-size: 0.14rem;
							font-family: Noto Sans CJK SC;
							font-weight: 400;
							color: #070606;
							line-height: 0.26rem;
							cursor: pointer;
							&.active {
								&::before {
									content: '';
									width: 0.16rem;
									height: 0.16rem;
									display: inline-block;
									vertical-align: middle;
									margin-top: -4px;
									margin-right: 0.09rem;
									background: url('~./images/star-active.png') left center / 0.16rem 0.16rem no-repeat;
								}
							}
							&::before {
								content: '';
								width: 0.16rem;
								height: 0.16rem;
								display: inline-block;
								vertical-align: middle;
								margin-top: -4px;
								margin-right: 0.09rem;
								background: url('~./images/shouc.png') center center / 100% 100%;
							}
						}
						.activity-time {
							font-size: 0.14rem;
							font-family: Noto Sans CJK SC;
							font-weight: 400;
							color: #070606;
							line-height: 0.26rem;
							margin-left: 0.2rem;
							&::before {
								content: '';
								width: 0.16rem;
								height: 0.16rem;
								display: inline-block;
								vertical-align: middle;
								margin-top: -4px;
								// margin-top: -1px;
								background: url('~./images/time.png') center center / 100% 100%;
								margin-right: 0.09rem;
							}
						}
						.tag-list {
							margin-top: 0.14rem;
							display: inline-block;
							margin-left: 0.3rem;
							span {
								padding: 0.04rem 0.1rem 0.05rem;
								background: #f0f0f0;
								border-radius: 0.02rem;
								font-size: 0.14rem;
								font-family: Noto Sans CJK SC;
								font-weight: 400;
								color: #333333;
								line-height: 0.32rem;
								margin-right: 0.1rem;
							}
						}
					}
					.item-right-btn {
						float: right;
						width: 1.4rem;
						height: 0.52rem;
						margin-right: 0.5rem;
						text-align: center;
						line-height: 0.52rem;
						cursor: pointer;
						margin-top: 0.5rem;
						background: url('~./images/baoming-bg.png') center center / 100% 100%;
						p {
							font-size: 0.18rem;
							font-family: Noto Sans CJK SC;
							font-weight: 500;
							color: #ffffff;
							// line-height: .5rem;
						}
						a {
							color: #ffffff;
						}
					}
				}
			}
		}

		ul {
			li {
				width: 4.6rem;
				float: left;
				margin-right: 0.6rem;
				cursor: pointer;
				.item-img {
					width: 4.6rem;
					height: 2.58rem;
					margin-bottom: 0.29rem;
				}
				.item-title {
					font-size: 0.22rem;
					font-family: Noto Sans CJK SC;
					font-weight: 500;
					color: #333333;
					line-height: 0.24rem;
					height: 0.24rem;
					width: 4.6rem;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					line-clamp: 1;
					-webkit-box-orient: vertical;
				}
				.tag-list {
					margin-top: 0.14rem;
					span {
						padding: 0.04rem 0.1rem 0.05rem;
						background: #f0f0f0;
						border-radius: 0.02rem;
						font-size: 0.14rem;
						font-family: Noto Sans CJK SC;
						font-weight: 400;
						color: #333333;
						line-height: 0.32rem;
						margin-right: 0.1rem;
					}
				}
				.time {
					font-size: 0.14rem;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #666666;
					line-height: 0.24rem;
					margin-top: 0.2rem;
				}

				.speaker {
					font-size: 0.14rem;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #666666;
					line-height: 0.26rem;
					margin-top: 0.15rem;

					span {
						display: inline-block;
						margin-right: 0.34rem;
						img {
							display: inline-block;
							vertical-align: middle;
							margin-top: -1px;
							width: 0.32rem;
							height: 0.32rem;
							margin-right: 0.1rem;
						}
						i {
							font-style: normal;
						}
					}
				}
			}
		}
	}

	.item-content-bottom {
		margin: 0.33rem 0 0 0.9rem;
		height: 4rem;
		overflow-x: hidden;
		overflow-y: scroll;
		.item-img {
			float: left;
			width: 7.29rem;
			height: 4.1rem;
			margin-right: 0.48rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.item-activity-detail {
		overflow: hidden;
		width: 4.63rem;
		.item-title {
			font-size: 0.18rem;
			font-family: Noto Sans CJK SC;
			font-weight: bold;
			color: #333333;
			line-height: 0.36rem;
			margin-bottom: 0.19rem;
		}

		.item-content-d {
			font-size: 0.16rem;
			font-family: Noto Sans CJK SC;
			font-weight: 400;
			color: #333333;
			line-height: 0.28rem;
			margin-bottom: 0.5rem;
		}

		.teacher-detail {
			float: left;
			margin-right: 0.4rem;
			img {
				float: left;
				width: 0.68rem;
				height: 0.68rem;
				margin-right: 0.1rem;
			}
			span {
				display: inline-block;
				overflow: hidden;
				i {
					font-size: 0.14rem;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #666666;
					line-height: 0.26rem;
					display: block;
					font-style: normal;
					&.item-name {
						font-size: 0.18rem;
						font-family: Noto Sans CJK SC;
						font-weight: 500;
						color: #333333;
						line-height: 0.26rem;
						margin-bottom: 0.1rem;
					}
				}
			}
		}

		.audit-no {
			clear: both;
			color: #666;
			padding-top: 0.1rem;
		}
	}

	.item-list-content {
		position: relative;
		background: url('~./images/content-bg.png') center center / 100% 100%;
		border-radius: 0.2rem;
		height: 6.22rem;
		.arrow {
			position: absolute;
			// right: -0.26rem;
			top: 1.5rem;
			// bottom: -0.26rem;
			width: 0.52rem;
			height: 0.52rem;
			border-radius: 100%;
			cursor: pointer;
			background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAWCAYAAAAb+hYkAAAA60lEQVQ4jY2STUoDQRCFXya7gAsX3iwSEH9AXYgDiSGi4spZeAGv4d1cOmqiXyjoIZWx6OmGB0VPffWmqkvACvgApoBKVEk6knQo6V3SsUpOqtywOydDbl0wBl6BP+AbOIuS+1CnF+c4i4AI6sAN0AJXwfcQMj06x/NSyA/nC7gohUwPzvGyFDI9J+g3gaMoKdKpc2yqog2QPl08iar2dZsc7OGXQBUleV2737ovGcRdav4nOQyO3DssSh53nqqv02b8y+lf1M7B4uzCVq6HvaZz0I1zMNiKZKGnXNORbCMOJLWSaklvg7shaQv3pBtlz4S4XAAAAABJRU5ErkJggg==)
				center center/0.13rem 0.22rem no-repeat #503291;
			box-shadow: 0.1rem 0 0.2rem 0 rgba(0, 0, 0, 0.1);
			z-index: 1;
			&:hover {
				background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAWCAYAAAAb+hYkAAAA60lEQVQ4jY2STUoDQRCFXya7gAsX3iwSEH9AXYgDiSGi4spZeAGv4d1cOmqiXyjoIZWx6OmGB0VPffWmqkvACvgApoBKVEk6knQo6V3SsUpOqtywOydDbl0wBl6BP+AbOIuS+1CnF+c4i4AI6sAN0AJXwfcQMj06x/NSyA/nC7gohUwPzvGyFDI9J+g3gaMoKdKpc2yqog2QPl08iar2dZsc7OGXQBUleV2737ovGcRdav4nOQyO3DssSh53nqqv02b8y+lf1M7B4uzCVq6HvaZz0I1zMNiKZKGnXNORbCMOJLWSaklvg7shaQv3pBtlz4S4XAAAAABJRU5ErkJggg==)
					center center/0.13rem 0.22rem no-repeat #eb3c96;
			}
		}
		.left {
			transform: rotate(180deg);
			top: 1.4rem !important;
			left: 1rem;
		}

		.right {
			right: 0.7rem;
		}
	}
}// 黑色弹框
.bullet {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	.bullet-pop {
		width: 3.73rem;
		height: 4.69rem;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-webkit-transform: translate(-50%, -50%);
		background: url('~./images/bg-bullet.png') no-repeat center;
		background-size: 100% auto;
		padding-top: 1.3rem;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		.bullet-close {
			width: 0.36rem;
			height: 0.36rem;
			position: absolute;
			top: 0.2rem;
			right: 0.45rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 0.27rem;
				height: 0.28rem;
			}
		}
		.bullet-code {
			width: 2.14rem;
			height: 2.14rem;
			margin-bottom: 0.3rem;
			background: #2dbecd;
			padding: 0.2rem;
		}
		.bullet-notes {
			width: 100%;
			margin: 0 auto;
			p {
				font-size: 0.176rem;
				color: #333333;
				line-height: 0.23rem;
				text-align: center;
			}
		}
	}
}
.ne_bookMeeting {
	margin-top: 0.5rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-left: 0.25rem;
	float: left;
	.booked_notes {
		font-size: 0.14rem;
		color: #777777;
	}
	.ne_bookMeeting_btn {
		color: #503291;
		border: 1px solid #503291;
		font-size: 0.16rem;
		&.activeds {
			border: 1px solid #f0f0f0;
			background: #f0f0f0;
			color: #777777;
			margin-left: 0.1rem;
		}
		&.proDetil {
			margin: 0;
			margin-right: 0.2rem;
		}
	}
}
</style>
